<template>
  <router-view />
</template>

<script>
import cookie from "@/mixins/cookie";

export default {
  name: 'App',
  mixins: [cookie],
  mounted() {
    let accessToken = this.getCookie('access_token');
    if (accessToken) {
      this.$store.commit('setAccessToken', accessToken);
    }
  }
};
</script>
