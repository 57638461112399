import axios from 'axios';
import store from '../store';
// import articles from '@/utils/articles';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
};

const AXIOS_CONFIG_AWS = {
  baseURL: process.env.VUE_APP_API_URL_AWS,
  headers: {
    'Content-Type': 'application/json'
  }
};

const httpClient = axios.create(AXIOS_CONFIG);

const httpClientAws = axios.create(AXIOS_CONFIG_AWS);

const getNodesInfo = async () => await httpClient.get('v3/access_nodes/nodes_v2');

const getArticles = (lang) => httpClient.get(`v3/posts/${lang}`);

const deleteArticle = (slug, lang, recaptchaToken) =>
  httpClient.delete(`v3/blog/posts/${lang}/${slug}`, {
    headers: {
      // @ts-ignore
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken
    }
  });

const saveArticle = (article, recaptchaToken) =>
  httpClient.post(`v3/blog/posts`, article, {
    headers: {
      // @ts-ignore
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken
    }
  });


const googleLogin = (googleToken, recaptchaToken) =>
  httpClientAws.post('/auth', { token: googleToken }, {
    headers: {
      'Content-Type': 'text/plain',
      'recaptcha-token': recaptchaToken
    },
    responseType: 'text'
  });

const getProjects = async () => await httpClient.get('v6/marketplace/projects?lang=en');

const getProject = async (slug: string) => await httpClient.get(`v6/marketplace/projects/${slug}?lang=en`);

const getCategories = async () => await httpClient.get('v6/marketplace/categories');

const deleteProject = async (slug: string, recaptchaToken: string) =>
  await httpClient.delete(`v6/marketplace/admin/projects/${slug}`, {
    headers: {
      // @ts-ignore
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken
    }
  });


const redactProject = async (projectId, project, recaptchaToken: string) => {
  await httpClient.put(`v6/marketplace/admin/projects/${projectId}?lang=en`, project, {
    headers: {
      // @ts-ignore
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken
    }
  });
};

const addNewProject = async (project, recaptchaToken: string) => {
  await httpClient.post(`v6/marketplace/admin/projects?lang=en`, project, {
    headers: {
      // @ts-ignore
      Authorization: `Bearer ${store.state.accessToken}`,
      'recaptcha-token': recaptchaToken
    }
  });
};

export {
  getArticles,
  saveArticle,
  deleteArticle,
  googleLogin,
  getNodesInfo,
  getProjects,
  getProject,
  getCategories,
  deleteProject,
  redactProject,
  addNewProject
};
