import { createStore } from 'vuex';
import { googleLogin } from '@/api';

function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    ...options
  };
  // @ts-ignore
  if (options.expires instanceof Date) options.expires = options.expires.toUTCString();
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

const store = createStore({
  state() {
    return {
      accessToken: '',
      googleToken: '',
      project: null
    };
  },
  mutations: {
    setAccessToken(state, token) {
      // @ts-ignore
      state.accessToken = token;
    },
    setGoogleToken(state, token) {
      // @ts-ignore
      state.googleToken = token;
    },
    setProject(state, project) {
      // @ts-ignore
      state.project = project;
    }
  },
  getters: {
    getAccessToken(state) {
      // @ts-ignore
      return state.accessToken;
    }
  },
  actions: {
    async googleAuth({ state, commit }, recaptchaToken) {
      // @ts-ignore
      const res = await googleLogin(state.googleToken, recaptchaToken);
      commit('setAccessToken', res.data);
      setCookie('access_token', res.data, {
        // 7 days
        expires: new Date(Date.now() + 604800e3)
      });
    }
  }
});

export default store;
