import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
// @ts-ignore
const Login = () => import('@/views/Login');
// @ts-ignore
const ArticlesTable = () => import('@/views/ArticlesTable');
// @ts-ignore
const RedactArticle = () => import('@/views/RedactArticle');
// @ts-ignore
const RedactProject = () => import('@/views/RedactProject');
// @ts-ignore
const ProjectsTable = () => import('@/views/ProjectsTable.vue');

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/',
    component: Login
  },
  {
    path: '/articles',
    name: 'ArticlesTable',
    component: ArticlesTable,
    beforeEnter: requireAuth,
  },
  {
    path: '/redact-article',
    name: 'RedactArticle',
    component: RedactArticle,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/projects',
    name: 'ProjectsTable',
    beforeEnter: requireAuth,
    component: ProjectsTable,
  },
  {
    path: '/redact-project',
    name: 'RedactProject',
    beforeEnter: requireAuth,
    component: RedactProject,
  },
];

function requireAuth(to, from, next) {
  if (!store.getters.getAccessToken) next('/');
  else next();
}

const router = createRouter({
  history: createWebHistory(),
  // @ts-ignore
  routes
});

export default router;
